.iconClass {
  position: relative;
}
.iconClass span {
  position: absolute;
  top: 0px;
  right: 0px;
  display: block;
  background-color: #595970;
  border-radius: 50%;
}

.one {
  background-color: #fff;
}

/* -------------------spinner code start------------------- */
#cover-spin {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(233, 241, 241, 0.7);
  z-index: 9999;
  display: none;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#cover-spin::after {
  content: "";
  display: block;
  position: absolute;
  left: 48%;
  top: 40%;
  width: 40px;
  height: 40px;
  border-style: solid;
  border-color: rgb(48, 10, 118);
  border-top-color: transparent;
  border-width: 4px;
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

/* -------------------spinner code end-------------------- */

.modal-content {
  width: 125% !important;
  margin-left: -123px !important;
}

@media (min-width: 310px) and (max-width: 1440px) {
  .modal-content {
    width: auto !important;
    margin-left: 0 !important;
  }
}

@media (min-width: 310px) and (max-width: 1435px) {
  .responsive-table {
    table-layout: none !important;
    font-size: smaller;
  }
}
.table-container {
  overflow-x: auto !important;
}

.responsive-table {
  width: 100% !important;
  border-collapse: collapse !important;
  table-layout: fixed !important;
}

.martop {
  position: absolute;
  top: 60%;
  left: 44%;
}

.Actcard {
  border-width: 0px 0px 8px 0px !important;
  border-color: #00d5ae !important;
}

.img-holder {
  margin: auto;
  width: 100px;
  height: 100px;
  margin-top: 1rem;
}

.img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

#input[type="file"] {
  display: none;
}

.label {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.image-upload {
  margin: auto;
  width: 200px;
  height: 50px;
  color: #00d5ae;
  border-radius: 10px;

  text-align: center;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.imged {
  width: 120px;
  height: 120px;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo_head {
  width: 15% !important;
}
.BTN_Color {
  background: linear-gradient(260deg, #1aa4c8, #00d5ae);
}
